exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dizajnova-imitacia-obkladov-index-js": () => import("./../../../src/pages/dizajnova-imitacia-obkladov/index.js" /* webpackChunkName: "component---src-pages-dizajnova-imitacia-obkladov-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-malovanie-index-js": () => import("./../../../src/pages/malovanie/index.js" /* webpackChunkName: "component---src-pages-malovanie-index-js" */),
  "component---src-pages-montaz-sadrokartonu-index-js": () => import("./../../../src/pages/montaz-sadrokartonu/index.js" /* webpackChunkName: "component---src-pages-montaz-sadrokartonu-index-js" */),
  "component---src-pages-renovacia-striech-a-fasad-index-js": () => import("./../../../src/pages/renovacia-striech-a-fasad/index.js" /* webpackChunkName: "component---src-pages-renovacia-striech-a-fasad-index-js" */),
  "component---src-pages-zateplovanie-rodinnych-domov-index-js": () => import("./../../../src/pages/zateplovanie-rodinnych-domov/index.js" /* webpackChunkName: "component---src-pages-zateplovanie-rodinnych-domov-index-js" */)
}

